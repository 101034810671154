






















import { UserProfileStore } from '@/stores/userProfileStore'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/profile/activity/pages/components/following/follow-button.vue'),
  },
})
export default class UserToFollowItem extends Vue {
  @Prop() profile!: UserProfileStore
  @Prop() hideFollowButton!: boolean
}
